/**
 * ClinicMapSearchBox
 * The search field component within the Google Maps component.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
// import isEmpty from '../../utils/isEmptyObject';

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const SearchBoxWrapper = (props) => {
  const domElementId = '#clinic-search-input';

  const searchFn = {
    searchBox: undefined,
  };

  const onSearchBoxMounted = (ref) => {
    searchFn.searchBox = ref;
    props.initialiseValue();
  };

  const onPlacesChanged = () => {
    const place = searchFn.searchBox.getPlaces()[0];
    const inputElement = document.querySelector(domElementId);
    props.onPlacesChanged(place, inputElement.value);

    //Get the value and store the sessionStorage
    sessionStorage.setItem('searchResult', inputElement.value);

    // Reapply focus to search input, as it would have been stolen by google autocomplete and reset to DOM top
    inputElement.focus();
  };

  /**
   * Google option to set the position of the search input field within the map component.
   * Note that the input field position is currently overridden by CSS.
   * @return {int} A number (1 - 13) interpreted as the position on the map.
   */
  const controlPosition = () => {
    const { google } = window;
    const position = google.maps.ControlPosition[props.controlPosition];
    return position;
  };
  return (
    <SearchBox
      ref={onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={controlPosition()}
      onPlacesChanged={onPlacesChanged}
    >
      {props.children}
    </SearchBox>
  );
};

class ClinicMapSearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialising: true,
    };
    this.inputElement = React.createRef();
    this.liveResults = React.createRef();
    this.initialiseValueOnMount = this.initialiseValueOnMount.bind(this);
  }

  // ACCESSIBILITY
  // ----------------------------------------------------

  // triggered by input onblur event
  searchReset(textObj) {
    // no search items - reset to default
    this.inputElement.current.setAttribute('aria-expanded', 'false');
    this.inputElement.current.setAttribute('aria-activedescendant', '');
    const textToRead = '';
    this.liveResults.current.innerHTML = textToRead;
  }

  // triggered by input keyup event
  searchAddress(textObj) {
    // whenever a search is triggered
    // when timeout, it triggers the search and always reads the last query
    if (document.querySelector('.pac-container') !== null) {
      // confirm container exists
      const list = document.getElementsByClassName('pac-container')[0]; // define list element

      let textToRead;
      // check if the list is visible
      if (list.offsetParent !== null) {
        const number = list.childElementCount;
        textToRead = `${number} ${textObj.resultsAvailable}`;
        // if results length === 0 then say "no search results"
        if (number === 0) {
          textToRead = textObj.noResults;
        }
        // input is empty
        if (
          this.inputElement.current.value === null ||
          this.inputElement.current.value === ''
        ) {
          textToRead = '';
        }
      } else {
        textToRead = '';
      }
      this.liveResults.current.innerHTML = textToRead;
    }
  }

  initialiseValueOnMount() {
    // Set search text based on location searched
    if (!this.props.fetchedGeoAddress) {
      this.props.fetchGeoAddress(
        (data) => {
          this.props.handleFetchedGeoAddress(data);
        },
        (err) => err,
        this.props.clientLoc,
      );

      // Set search text based on previously inputted text
      // re: this.inputElement, wait for refs to populate
    } else if (this.inputElement && this.state.initialising) {
      this.setState({
        initialising: false,
      });

      //this.inputElement.current.value = sessionStorage.getItem('searchResult') ? sessionStorage.getItem('searchResult') : this.props.valueSearched;
      this.inputElement.current.value = this.props.valueSearched;
      // if search input exists, then the pac-container should also exist
      if (document.querySelector('.pac-container') !== null) {
        const list = document.getElementsByClassName('pac-container')[0];
        list.setAttribute('id', 'pac-results');
        list.setAttribute('role', 'listbox');
      }
    }
  }

  render() {
    const { bounds, onPlacesChanged, placeholderText, controlPosition } =
      this.props;
    // const screenReaderResultsText = {}; // todo: add screenreader values
    const newBounds = isEmpty(bounds) ? null : bounds;
    return (
      <div>
        <SearchBoxWrapper
          bounds={newBounds}
          onPlacesChanged={onPlacesChanged}
          initialiseValue={this.initialiseValueOnMount}
          controlPosition={controlPosition}
        >
          <input
            ref={this.inputElement}
            id="clinic-search-input"
            type="search"
            autoComplete="off"
            placeholder={placeholderText}
            aria-describedby="initInstructions"
            aria-owns="pac-results"
            aria-expanded="false"
            aria-autocomplete="both"
            aria-activedescendant=""
            // onKeyUp={e => this.searchAddress(screenReaderResultsText)}
            // onFocus={e => this.searchAddress(screenReaderResultsText)}
            // onBlur={e => this.searchReset(screenReaderResultsText)}
          />
        </SearchBoxWrapper>
      </div>
    );
  }
}

ClinicMapSearchBox.propTypes = {
  bounds: PropTypes.object,
  clientLoc: PropTypes.object,
  fetchedGeoAddress: PropTypes.bool,
  fetchGeoAddress: PropTypes.func,
  handleFetchedGeoAddress: PropTypes.func,
  onPlacesChanged: PropTypes.func,
  valueSearched: PropTypes.string,
  placeholderText: PropTypes.string,
  controlPosition: PropTypes.string,
};

ClinicMapSearchBox.defaultProps = {
  bounds: {},
  clientLoc: {},
  fetchedGeoAddress: false,
  fetchGeoAddress: () => {},
  handleFetchedGeoAddress: () => {},
  onPlacesChanged: () => {},
  valueSearched: '',
  controlPosition: '',
};

SearchBoxWrapper.propTypes = {
  bounds: PropTypes.object,
  children: PropTypes.node,
  controlPosition: PropTypes.string,
  initialiseValue: PropTypes.func,
  onPlacesChanged: PropTypes.func,
};

SearchBoxWrapper.defaultProps = {
  bounds: {},
  children: null,
  controlPosition: '',
  initialiseValue: () => {},
  onPlacesChanged: () => {},
};

export default ClinicMapSearchBox;
