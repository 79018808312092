import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClinicListTitleBar from './ClinicListTitleBar';
import ClinicFilterChipsPanel from './ClinicFilterChipsPanel';
import Link from '../../../../core/Components/Link';
import PageLoadSpinner from '../../../../core/Components/PageLoadSpinner';
import { countriesMiles } from './utils';

function addhttp(url) {
  const pattern = /^((http|https|ftp):\/\/)/;
  return !pattern.test(url) ? `http://${url}` : url;
}

function dialogTech() {
  const element = document.getElementById('dtech');
  if (element != null) {
    element.parentNode.removeChild(element);
  }
  if (window._st) {
    window._st.snippets = 0;
  }
  window._stk = '06617d4f55914c0b2a2718fdee9ec71dcc95c2ff';

  (function () {
    let a = document,
      b = a.createElement('script');
    b.id = 'dtech';
    b.type = 'text/javascript';
    b.async = !0;
    b.src = `${
      document.location.protocol === 'https:' ? 'https://' : 'http://'
    }d31y97ze264gaa.cloudfront.net/assets/st/js/st.js`;
    a = a.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(b, a);
  })();
}

class ClinicList extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    // Set search text based on location searched
    if (this.props.searchCoords !== nextProps.searchCoords) {
      if (this.props.fetchedGeoAddress) {
        const latitude = nextProps.searchCoords.lat;
        const longitude = nextProps.searchCoords.lng;
        const coords = { coords: { latitude, longitude } };
        this.props.fetchGeoAddress(
          (data) => {
            this.props.handleFetchedGeoAddress(data);
          },
          (err) => {
            console.log('error', err);
          },
          coords,
        );
      }
    }
  }

  render() {
    const cssClinicShowClass = 'show';

    const {
      clinicList,
      fetchClinicListPending,
      fetchFilteredResults,
      handleClinicSelected,
      handleClinicDeSelected,
      handleFilterSelected,
      handleFilterDeSelected,
      handleScrollBottom,
      showFilters,
      searchCoords,
      selectedClinic,
      selectedFilterArray,
      selectedFilters,
      sitecoreFields,
      isFiltering,
      invalidAddress,
      invalidAddressValue,
      valueSearched,
      filterPanelUpdateFilterValues,
      setProductFilters,
    } = this.props;

    let { pageState } = this.props;
    let headerCountry = this.props.sitecoreFields.configuration.url.substring(
      1,
      3,
    );
    const handleClinicClick = (clinic) => {
      handleClinicSelected(clinic);
      //Get the value and store the sessionStorage
      sessionStorage.setItem('clinicSess', clinic);
    };
    const handleBookAppointmentBtn = (clinic) => {
      // clinicId=${clinic.id}&clinicName=${encodeURI(clinic.name)}&clinicEmail=${clinic.email}`}

      sessionStorage.setItem('clinicId', clinic.id);
      sessionStorage.setItem('clinicName', clinic.name);
      sessionStorage.setItem('clinicEmail', clinic.email);
      window.location = sitecoreFields.url_requestAppointment.value.href;
    };

    const filterChipsPanel = selectedFilters.length !== 0 && !showFilters && (
      <ClinicFilterChipsPanel
        filterPanelUpdateFilterValues={filterPanelUpdateFilterValues}
        selectedFilters={selectedFilters}
        setProductFilters={setProductFilters}
        fetchFilteredResults={fetchFilteredResults}
        fetchClinicListPending={fetchClinicListPending}
      />
    );

    if (!fetchClinicListPending) {
      if (invalidAddress) {
        return (
          <div
            className={`clinic-list no-results ${
              showFilters ? 'filters-open' : 'filters-closed'
            }`}
          >
            <ClinicListTitleBar
              pageState={pageState}
              count={0}
              handleFilterSelected={handleFilterSelected}
              handleFilterDeSelected={handleFilterDeSelected}
              showFilters={showFilters}
              selectedFilterArray={selectedFilterArray}
              isFiltering={isFiltering}
              sitecoreFields={sitecoreFields}
            />
            {filterChipsPanel}
            <div className="clinicResults no-clinics">
              <p className="results-text">{`${sitecoreFields.result_invalidResult_1.value}'${invalidAddressValue}'${sitecoreFields.result_invalidResult_2.value}`}</p>
            </div>
          </div>
        );
      }

      // if there are clinics found
      if (clinicList && clinicList.length > 0) {
        const active = selectedClinic ? 'active' : '';
        pageState = selectedClinic ? 'detail' : 'list';
        return (
          <div
            className={`clinic-list ${
              showFilters ? 'filters-open' : 'filters-closed'
            }`}
          >
            <ClinicListTitleBar
              count={clinicList.length}
              pageState={pageState}
              handleClinicDeSelected={handleClinicDeSelected}
              handleFilterSelected={handleFilterSelected}
              handleFilterDeSelected={handleFilterDeSelected}
              isFiltering={isFiltering}
              showFilters={showFilters}
              selectedFilterArray={selectedFilterArray}
              sitecoreFields={sitecoreFields}
            />
            {filterChipsPanel}
            <ul className={active} onScroll={handleScrollBottom}>
              {clinicList.map((clinic) => {
                const classVisibleState =
                  selectedClinic === clinic.id ? cssClinicShowClass : '';
                const showInMiles =
                  countriesMiles.indexOf(headerCountry) !== -1;
                const nonBreakingSpace = '\xa0';

                function round(value, decimals) {
                  const a = `${value}e${decimals}`;
                  return Number(`${Math.round(a)}e-${decimals}`);
                }

                /* const filterValue = (clinic) => {
									const keys = Object.keys(clinic);
									const retObj = {};
									keys.forEach((item) => {
										retObj[item] = (clinic[item] && clinic[item] !== 'NULL') ? clinic[item] : '';
									})
									return retObj;
								}; */
                /* const { mapAddressStreet, mapAddressCity, mapAddressPostalCode, mapAddressCountry } = filterValue(clinic); */

                const filterValue = (value) => {
                  return !!value && value !== 'NULL' ? value : '';
                };

                const distance = showInMiles
                  ? clinic.distanceInMiles
                  : clinic.distanceInKm;
                const formattedDistance = showInMiles
                  ? distance < 0.1
                    ? `${Math.ceil(distance * 5280)}${nonBreakingSpace}ft`
                    : `${round(distance, 1)}${nonBreakingSpace}mi.`
                  : distance < 0.1
                  ? `${Math.ceil(distance * 1000)}${nonBreakingSpace}m`
                  : `${round(distance, 1)}${nonBreakingSpace}km.`;

                // const mapAddressPostalState = (clinic.mapAddressPostalState && clinic.mapAddressPostalState !== 'NULL')  ? `${clinic.mapAddressPostalState}, ` : '';
                const mapAddressStreet = filterValue(clinic.mapAddressStreet);
                const mapAddressPostalState = filterValue(
                  clinic.mapAddressPostalState,
                );
                const mapAddressCity = filterValue(clinic.mapAddressCity);
                const mapAddressPostalCode = filterValue(
                  clinic.mapAddressPostalCode,
                );
                const mapAddressCountry = filterValue(clinic.mapAddressCountry);
                const renderComma = (arrWord) => {
                  return !!arrWord.filter((word) => word.length > 0).length
                    ? ','
                    : '';
                };

                const clinicAddressString = `${mapAddressStreet} ${mapAddressCity}${renderComma(
                  [mapAddressStreet, mapAddressCity],
                )} ${mapAddressPostalState} ${mapAddressPostalCode}${renderComma(
                  [mapAddressPostalState, mapAddressPostalCode],
                )} ${mapAddressCountry}`;

                const clinicAddressHTML = (
                  <span>
                    {mapAddressStreet} {mapAddressCity}
                    {renderComma([mapAddressStreet, mapAddressCity])}
                    <br />
                    {mapAddressPostalState} {mapAddressPostalCode}
                    {renderComma([
                      mapAddressPostalState,
                      mapAddressPostalCode,
                    ])}{' '}
                    {mapAddressCountry}
                  </span>
                );

                let childrenText;
                let adultsText;

                childrenText = sitecoreFields.filter_clinicType_children.value
                  ? sitecoreFields.filter_clinicType_children.value
                  : 'Children';
                adultsText = sitecoreFields.filter_clinicType_adults.value
                  ? sitecoreFields.filter_clinicType_adults.value
                  : 'Adults';

                return (
                  <li
                    key={clinic.id}
                    data-id={clinic.id}
                    className={classVisibleState}
                  >
                    {classVisibleState && dialogTech()}
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => handleClinicClick(clinic)}
                      onClick={() => handleClinicClick(clinic)}
                    >
                      <div className="clinicListing li-inner">
                        <div className="clinicListing__clinic-name">
                          {clinic.name}
                        </div>
                        <div className="clinicListing__clinic-product-offers">
                          <Offers
                            products={clinic.productHash}
                            types={clinic.clinicHash}
                            productTypes={
                              sitecoreFields.configuration.fields.productTypes
                                .fields.productTypes
                            }
                            childrenText={childrenText}
                            adultsText={adultsText}
                          />
                        </div>
                        <div className="clinicListing__clinic-address">
                          <p>
                            {formattedDistance}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            {clinicAddressHTML}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`clinicDetails clinic-details-view ${classVisibleState}`}
                      >
                        <div className="clinicDetails__inner-title clinicDetails--padding">
                          <span className="clinicDetails__clinic-name">
                            {clinic.name}
                          </span>
                          <span className="clinicDetails__clinic-distance">
                            {formattedDistance}
                          </span>
                        </div>
                        <div className="clinicDetails__clinic-product-offers clinicDetails--padding">
                          <Offers
                            products={clinic.productHash}
                            types={clinic.clinicHash}
                            productTypes={
                              sitecoreFields.configuration.fields.productTypes
                                .fields.productTypes
                            }
                            childrenText={childrenText}
                            adultsText={adultsText}
                          />
                        </div>
                        {clinic.email &&
                          sitecoreFields.detail_requestAppointmentShow
                            .value && (
                            <div className="clinicDetails__clinic-btn-book clinicDetails--padding">
                              {/* Request appointment link */}
                              <Link
                                field={sitecoreFields.url_requestAppointment}
                                onClick={() => handleBookAppointmentBtn(clinic)}
                                // href={`${sitecoreFields.url_requestAppointment.value.href}?clinicId=${clinic.id}&clinicName=${encodeURI(clinic.name)}&clinicEmail=${clinic.email}`}
                                className={'btn btn--primary btn--yellow'}
                              >
                                {sitecoreFields.detail_requestAppointment.value}
                              </Link>

                              {/* <Link 
															onClick={() => {
																localStorage.setItem('clinicFinder', JSON.stringify({
																	clinicId: clinic.id,
																	clinicName: encodeURI(clinic.name),
																	clinicEmail: clinic.email,
																}));
																// localStorage.setItem('clinicId', clinic.id);
																// localStorage.setItem('clinicName', encodeURI(clinic.name));
																// localStorage.setItem('clinicEmail', clinic.email);
															}}
															field={sitecoreFields.url_requestAppointment}
															href={sitecoreFields.url_requestAppointment.value.href}
															className={"btn btn--primary btn--yellow"}
															rel="noopener noreferrer"
															target="_blank"
														>
															{sitecoreFields.detail_requestAppointment.value}
														</Link> */}
                              {/* <NavLink field={sitecoreFields.url_requestAppointment}
															onClick={() => {
																localStorage.setItem('clinicId', clinic.id);
																localStorage.setItem('clinicName', encodeURI(clinic.name));
																localStorage.setItem('clinicEmail', clinic.email);
															}}
															to={{
																pathname: sitecoreFields.url_requestAppointment.value.href,
																// state: {
																// 	clinicId: clinic.id,
																// 	clinicName: encodeURI(clinic.name),
																// 	clinicEmail: clinic.email,
																// }
															}}
															className={"btn btn--primary btn--yellow"}
															rel="noopener noreferrer"
															target="_blank"
														>
															{sitecoreFields.detail_requestAppointment.value}
														</NavLink> */}
                            </div>
                          )}
                        {clinic.mapAddressStreet && (
                          <Destination
                            clinicName={clinic.name}
                            clinicAddress={clinicAddressString}
                            searchCoords={searchCoords}
                            valueSearched={valueSearched}
                            sitecoreFields={sitecoreFields}
                          />
                        )}
                        {clinic.phone && (
                          <div
                            className={`clinicDetails__clinic-phone clinicDetails--padding clinicDetails--dark-border ${
                              clinic.email
                                ? ''
                                : 'clinicDetails--no-bottom-border'
                            }`}
                          >
                            <a
                              className="clinicDetails__contact-link"
                              href={`tel:${clinic.phone}`}
                              target="_top"
                            >
                              {clinic.phone}
                            </a>
                          </div>
                        )}
                        {clinic.email && (
                          <div className="clinicDetails__clinic-email clinicDetails--padding clinicDetails--dark-border clinicDetails--no-bottom-border">
                            <a
                              className="clinicDetails__contact-link"
                              href={`mailto:${clinic.email}`}
                              target="_top"
                            >
                              {clinic.email}
                            </a>
                          </div>
                        )}
                        {clinic.website && (
                          <div className="clinicDetails__clinic-website clinicDetails--padding clinicDetails--dark-border clinicDetails--no-bottom-border">
                            <a
                              className="clinicDetails__contact-link"
                              href={addhttp(clinic.website)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {clinic.website}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
              {clinicList.length > 2 && (
                <li
                  className={`scroll-item ${
                    showFilters ? 'filter-active' : ''
                  }`}
                >
                  <div className="scroll-bottom">
                    <span className="scroll-arrow" />
                  </div>
                </li>
              )}
            </ul>
          </div>
        );
      }

      // else no clinics
      return (
        <div
          className={`clinic-list no-results ${
            showFilters ? 'filters-open' : 'filters-closed'
          }`}
        >
          <ClinicListTitleBar
            pageState={pageState}
            count={0}
            handleFilterSelected={handleFilterSelected}
            handleFilterDeSelected={handleFilterDeSelected}
            showFilters={showFilters}
            selectedFilterArray={selectedFilterArray}
            isFiltering={isFiltering}
            sitecoreFields={sitecoreFields}
          />
          <div className="clinicResults no-clinics">
            <p className="results-text">
              {sitecoreFields.result_noResult.value}
            </p>
            <a
              href={sitecoreFields.url_contactUs.value}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--secondary btn--purple"
            >
              {sitecoreFields.result_contactCochlear.value}
            </a>
          </div>
        </div>
      );
    }
    // else fetch pending
    return (
      <div className="clinic-list">
        <PageLoadSpinner />
      </div>
    );
  }
}

ClinicList.propTypes = {
  clinicList: PropTypes.arrayOf(PropTypes.object),
  fetchClinicListPending: PropTypes.bool.isRequired,
  fetchFilteredResults: PropTypes.func.isRequired,
  handleClinicSelected: PropTypes.func.isRequired,
  handleClinicDeSelected: PropTypes.func.isRequired,
  handleFilterSelected: PropTypes.func.isRequired,
  handleFilterDeSelected: PropTypes.func.isRequired,
  handleScrollBottom: PropTypes.func.isRequired,
  invalidAddress: PropTypes.bool.isRequired,
  invalidAddressValue: PropTypes.string,
  isFiltering: PropTypes.bool.isRequired,
  pageState: PropTypes.string.isRequired,
  searchCoords: PropTypes.objectOf(PropTypes.number),
  selectedClinic: PropTypes.string,
  selectedFilterArray: PropTypes.arrayOf(PropTypes.string),
  selectedFilters: PropTypes.array,
  setProductFilters: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  sitecoreFields: PropTypes.object.isRequired,
  valueSearched: PropTypes.string,
  fetchGeoAddress: PropTypes.func.isRequired,
  fetchedGeoAddress: PropTypes.bool.isRequired,
  handleFetchedGeoAddress: PropTypes.func.isRequired,
  filterPanelUpdateFilterValues: PropTypes.func.isRequired,
};

ClinicList.defaultProps = {
  clinicList: [],
  invalidAddressValue: '',
  searchCoords: null,
  selectedClinic: null,
  selectedFilterArray: [],
  selectedFilters: [],
  valueSearched: '',
};

const Destination = (props) => {
  // https://developers.google.com/maps/documentation/urls/guide
  const {
    clinicName,
    clinicAddress,
    searchCoords,
    valueSearched,
    sitecoreFields,
  } = props;
  const search = encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${clinicName}, ${clinicAddress}`,
  );
  const { lat, lng } = searchCoords;
  const coords = `${lat},${lng}`;
  const origin = valueSearched !== null ? valueSearched : coords;
  const link = encodeURI(
    `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${clinicAddress}`,
  );
  return (
    <div className="clinicDetails__clinic-address clinicDetails--padding">
      <p>
        <a href={search} target="_blank" rel="noopener noreferrer">
          {clinicAddress}
        </a>
      </p>
      <a
        href={link}
        className="btn btn--secondary btn--purple"
        target="_blank"
        rel="noopener noreferrer"
      >
        {sitecoreFields.detail_directions.value}
      </a>
    </div>
  );
};
Destination.propTypes = {
  clinicName: PropTypes.string.isRequired,
  clinicAddress: PropTypes.any.isRequired,
  searchCoords: PropTypes.objectOf(PropTypes.number).isRequired,
  valueSearched: PropTypes.string,
};
Destination.defaultProps = {
  valueSearched: null,
};

let productsList;

// Object.entries()
// Polyfill for IE

if (!Object.entries) {
  Object.entries = function (obj) {
    const ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }

    return resArray;
  };
}

function clinicFinderProductBitwise(aggregatePid) {
  const output = [];
  for (const [key, value] of Object.entries(productsList)) {
    if (aggregatePid & key) {
      output[key] = value;
    }
  }
  return output;
}

const Offers = (props) => {
  const { products, types, productTypes, childrenText, adultsText } = props;

  // DBR-3522: Use of Sitecore Product Types
  if (productTypes) {
    productsList = {};
    productTypes.map((productType) => {
      productsList[productType.fields.hash.value] = {
        name: productType.fields.title.value,
      };
    });
  }

  const allProducts = productsList;
  const availableProducts = clinicFinderProductBitwise(products);
  let productDescription = '';
  let divider;
  let index = 1;
  const objLength = Object.entries(availableProducts).length - 1;
  for (const [key] of Object.entries(availableProducts)) {
    divider = index === objLength ? ' & ' : ', ';
    productDescription += `${allProducts[key].name}${
      index <= objLength ? divider : ''
    }`;
    index += 1;
  }
  let typeLabel;
  switch (types) {
    case 1:
      typeLabel = childrenText;
      break;
    case 2:
      typeLabel = adultsText;
      break;
    default:
      typeLabel = `${adultsText} & ${childrenText}`;
  }
  return (
    <span>
      {productDescription} | {typeLabel}
    </span>
  );
};

Offers.propTypes = {
  products: PropTypes.number.isRequired,
  types: PropTypes.number.isRequired,
};

export default ClinicList;
