import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import FormInput from './FormInput/FormInput';

let products, formLabel;

const initialState = {
  resetFormInputs: false,
  selectedClinicString: [],
  selectedProductString: [],
  selectedClinicHash: null,
  selectedProductHash: null,
  // selectedClinicTypeArray: [],
};

class ClinicFilters extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleClickForclinicHash = this.handleClickForclinicHash.bind(this);
    this.fetchFilteredResults = this.fetchFilteredResults.bind(this);
    this.handleClickForHearingSolutionType =
      this.handleClickForHearingSolutionType.bind(this);
    this.handleClickedApplyBtn = this.handleClickedApplyBtn.bind(this);
    this.resetFilterValues = this.resetFilterValues.bind(this);
  }

  handleClickedApplyBtn(event) {
    this.props.handleClickedApplyFilter(event);
  }

  handleClickForclinicHash(event) {
    // get option label
    const optionObject = this.props.filterPanelSelectedProductType.filter(
      (filter) => filter.hashId !== event.target.value,
    );
    // optionObject and filterPanelSelectedProductType have same length implies nothing has been unchecked so we push in new item to be checked.
    // Without this we would remove the item, but then recheck it before updating
    if (
      optionObject.length === this.props.filterPanelSelectedProductType.length
    ) {
      const { label } = formLabel.clinicType.options.filter(
        (item) => String(item.value) === String(event.target.value),
      )[0];
      optionObject.push({
        type: 'clinicType',
        hashId: event.target.value,
        value: label,
      });
    }
    this.props.filterPanelSetProductType(optionObject);
  }

  /**
   * Method handler for checkbox click
   * Updates the checkbox items selection state
   * @param  {object} event [The event object]
   */
  handleClickForHearingSolutionType(event) {
    const isChecked = event.target.checked;
    const itemValue = event.target.value; // product hash ID of currently handled checkbox
    const update = this.props.filterPanelSelectedProductList.map((item) =>
      String(item.hashId),
    ); // array of selected products

    // If checkbox handled is checked then add to `update`
    if (isChecked) {
      update.push(itemValue);
      // else it must be unchecked. Find and remove from `update`
    } else {
      const index = update.indexOf(itemValue);
      if (index > -1) {
        // should always be true
        update.splice(index, 1);
      }
    }

    // (Gist #1)
    // Create product filter objects from product array
    let selection = products.map((product) => {
      if (update.indexOf(product.value.toString()) !== -1) {
        return {
          type: 'clinicProduct',
          value: product.label,
          hashId: product.value,
        };
      }
      return undefined;
    });

    // Remove items that are 'undefined' or null
    selection = selection.filter((val) => val !== undefined && val !== null);
    this.props.filterPanelSetProductList(selection);
  }

  resetFilterValues() {
    this.setState(initialState);
  }

  fetchFilteredResults() {
    const coords = new this.props.google.maps.LatLng({
      lat: this.props.searchCoords.lat,
      lng: this.props.searchCoords.lng,
    });
    const params = {
      coords,
      productHash: this.state.selectedProductHash,
      clinicHash: this.state.selectedClinicHash,
    };
    this.props.updateFilters(params);
  }

  render() {
    const {
      fetchClinicListPending,
      filterPanelSelectedProductList,
      filterPanelSelectedProductType,
      sitecoreFields,
    } = this.props;
    const classBeenClicked = fetchClinicListPending
      ? 'been-clicked fetching'
      : '';

    const productTypes =
      sitecoreFields.configuration.fields.productTypes.fields.productTypes;

    // DBR-3522: Use of Sitecore Product Types
    if (productTypes) {
      products = productTypes.map((productType) => {
        return {
          label: productType.fields.title.value,
          value: productType.fields.hash.value,
        };
      });
    }

    formLabel = {
      clinicType: {
        label: sitecoreFields.filter_clinicType.value
          ? sitecoreFields.filter_clinicType.value
          : 'Clinic Type',
        options: [
          {
            label: sitecoreFields.filter_clinicType_children.value
              ? sitecoreFields.filter_clinicType_children.value
              : 'Adults',
            value: 1,
          },
          {
            label: sitecoreFields.filter_clinicType_adults.value
              ? sitecoreFields.filter_clinicType_adults.value
              : 'Children',
            value: 2,
          },
        ],
      },
      HearingSolutionType: {
        label: sitecoreFields.filter_hearingSolutionType,
        options: products,
      },
    };

    return (
      <div className="filterList">
        <div className="filterList__group">
          <p className="label">{sitecoreFields.filter_clinicType.value}</p>
          <ClinicTypeCheckboxGroup
            formLabel={formLabel}
            handleClickForClinicType={this.handleClickForclinicHash}
            filterPanelSelectedProductType={filterPanelSelectedProductType}
          />
        </div>
        <div className="filterList__group">
          <p className="label">
            {sitecoreFields.filter_hearingSolutionType.value}
          </p>
          <CheckboxGroup
            formLabel={formLabel}
            handleClickForHearingSolutionType={
              this.handleClickForHearingSolutionType
            }
            products={products}
            filterPanelSelectedProductList={filterPanelSelectedProductList}
          />
        </div>
        <button
          type="button"
          className={`btn btn--primary btn--yellow ${classBeenClicked}`}
          onClick={this.handleClickedApplyBtn}
        >
          {sitecoreFields.filter_applyFilter.value}
        </button>
      </div>
    );
  }
}

ClinicFilters.propTypes = {
  fetchClinicListPending: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  handleClickedApplyFilter: PropTypes.func,
  filterPanelSetProductType: PropTypes.func,
  filterPanelSetProductList: PropTypes.func,
  filterPanelSelectedProductList: PropTypes.array.isRequired,
  filterPanelSelectedProductType: PropTypes.array.isRequired,
  searchCoords: PropTypes.objectOf(PropTypes.number),
  sitecoreFields: PropTypes.object.isRequired,
  google: PropTypes.object,
};

ClinicFilters.defaultProps = {
  searchCoords: null,
  google: null,
  handleClickedApplyFilter: () => {},
  filterPanelSetProductType: () => {},
  filterPanelSetProductList: () => {},
};

const CheckboxGroup = (props) => {
  const checkboxGroup = [];
  const { handleClickForHearingSolutionType, filterPanelSelectedProductList } =
    props;
  const selectedProductIDArray = filterPanelSelectedProductList.map((item) =>
    String(item.hashId),
  );
  for (
    let i = 0;
    i < props.formLabel.HearingSolutionType.options.length;
    i += 1
  ) {
    // if current checkbox value exists in selectedProductIDArray, then render as checked
    const isChecked =
      selectedProductIDArray.indexOf(
        props.formLabel.HearingSolutionType.options[i].value.toString(),
      ) !== -1;
    checkboxGroup.push(
      <label
        key={i}
        className="check-container"
        htmlFor={`filter-hearing-solution-type-${i}`}
      >
        <input
          value={props.formLabel.HearingSolutionType.options[i].value}
          name="filter-hearing-solution-type"
          id={`filter-hearing-solution-type-${i}`}
          className="checkbox"
          type="checkbox"
          onChange={handleClickForHearingSolutionType}
          checked={isChecked}
        />
        {props.formLabel.HearingSolutionType.options[i].label}
        <span className="checkmark" />
      </label>,
    );
  }
  return checkboxGroup;
};

const ClinicTypeCheckboxGroup = ({
  filterPanelSelectedProductType,
  formLabel,
  handleClickForClinicType,
}) =>
  formLabel.clinicType.options.map((opt) => {
    const isChecked =
      filterPanelSelectedProductType.filter(
        (type) => Number(type.hashId) === Number(opt.value),
      ).length > 0;
    return (
      <label
        htmlFor={`filter-clinic-type-${opt.value}`}
        key={opt.label}
        className="check-container"
      >
        <input
          value={opt.value}
          name="filter-clinic-type"
          id={`filter-clinic-type-${opt.value}`}
          className="checkbox"
          type="checkbox"
          onChange={handleClickForClinicType}
          checked={isChecked}
        />
        {opt.label}
        <span className="checkmark" />
      </label>
    );
  });

export default ClinicFilters;
