import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import ClinicNearYou from './ClinicNearYou';
import PageLoadSpinner from '../../../../core/Components/PageLoadSpinner';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class FindAClinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
      valueSearched: null,
      fetchedGeoAddress: false,
      selectedClinicShown: false,
      endpoint: props.sitecoreFields.configuration.fields.url.value,
    };
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
    this.handleFetchedGeoAddress = this.handleFetchedGeoAddress.bind(this);
  }

  componentWillMount() {
    this.fetchGeoLocationClinics();
  }

  handlePlaceChange(searchString) {
    this.setState({
      isSearching: true,
      valueSearched: searchString,
      selectedClinicShown: true,
    });
  }

  handleFetchedGeoAddress(results) {
    const len = results.length;
    let searchString = '';
    for (let i = 0; i < len; i += 1) {
      if (results[i].types[0] === 'postal_code') {
        searchString = results[i].formatted_address;
        break;
      }
    }
    this.setState({
      fetchedGeoAddress: true,
      valueSearched: searchString,
    });
  }

  fetchGeoLocationClinics() {
    const { clinicId } = this.props;
    const {
      clinicHash,
      count,
      rk,
      url,
      productHash,
      radius,
      // lat,
      // long,
    } = this.props.sitecoreFields.configuration.fields;
    const config = {
      endpoint: url.value,
      radius: radius.value,
      count: count.value,
      rk: rk.value,
      productHash: productHash.value,
      clinicHash: clinicHash.value,
    };
    if (clinicId) {
      this.props.fetchClinicList({
        clinicId: clinicId,
        config: config,
      });
    } else {
      // fetch geo location before fetching clinic
      this.props.fetchGeoLocation(
        (position) => {
          position.config = config;
          this.props.fetchClinicList(position);
        },
        (err) => {
          console.log('error:', err);
        },
      );
    }
  }

  render() {
    const {
      clinicId,
      clinicList,
      clientLocationAddress,
      clientLocation,
      country,
      fetchClinicList,
      fetchClinicListPending,
      fetchGeoAddress,
      fetchGeoLocation,
      fetchGeoLocationPending,
      fetchingGeoLocationFirstTime,
      searchCoords,
      sitecoreFields,
      sitecoreContext,
    } = this.props;

    // todo: add province filter toggle
    // const showProvinceClinicsOnly = sitecoreFields.showProvinceClinicsOnly;

    // When on canadian Library or search region is in Canada --- Show only clinics within the search province.
    const clinicListFilter = () => {
      if (
        country === 'ca' ||
        (clientLocationAddress &&
          clientLocationAddress.country.toLowerCase() === 'ca')
      ) {
        return clinicList
          ? clinicList.filter(
              (c) =>
                clientLocationAddress &&
                c.mapAddressPostalState === clientLocationAddress.province,
            )
          : null;
      }
      return clinicList;
    };
    // const clinicCoords = {
    // 	lat: clientLocation ? clientLocation.coords.latitude : 0,
    // 	lng: clientLocation ? clientLocation.coords.longitude : 0,
    // 	country: clientLocationAddress && clientLocationAddress.country,
    // 	province: clientLocationAddress && clientLocationAddress.province,
    // };
    return (
      <main>
        {fetchGeoLocationPending && fetchingGeoLocationFirstTime && (
          <div className="l-content-column">
            <PageLoadSpinner />
          </div>
        )}
        {!fetchingGeoLocationFirstTime && (
          <ClinicNearYou
            clinicId={clinicId}
            // clinicList={clinicList}
            clinicList={clinicListFilter()}
            clientLoc={clientLocation}
            // description={this.props.sitecoreFields.description.value}
            fetchClinicListPending={fetchClinicListPending}
            fetchedGeoAddress={this.state.fetchedGeoAddress}
            fetchGeoAddress={fetchGeoAddress}
            fetchGeoLocation={fetchGeoLocation}
            fetchGeoLocationPending={fetchGeoLocationPending}
            handleFetchedGeoAddress={this.handleFetchedGeoAddress}
            handlePlaceChange={this.handlePlaceChange}
            isSearching={this.state.isSearching}
            searchCoords={searchCoords}
            selectedClinicShown={this.state.selectedClinicShown}
            // title={this.props.sitecoreFields.description.title}
            updateClinicList={fetchClinicList}
            valueSearched={this.state.valueSearched}
            sitecoreFields={sitecoreFields}
            sitecoreContext={sitecoreContext}
          />
        )}
      </main>
    );
  }
}

FindAClinic.propTypes = {
  clinicList: PropTypes.arrayOf(PropTypes.object),
  clientLocation: PropTypes.objectOf(PropTypes.object),
  clientLocationAddress: PropTypes.object,
  fetchClinicList: PropTypes.func.isRequired,
  fetchClinicListPending: PropTypes.bool.isRequired,
  fetchGeoAddress: PropTypes.func.isRequired,
  fetchGeoLocation: PropTypes.func.isRequired,
  fetchGeoLocationPending: PropTypes.bool.isRequired,
  fetchingGeoLocationFirstTime: PropTypes.bool.isRequired,
  searchCoords: PropTypes.objectOf(PropTypes.number),
  country: PropTypes.string,
};

FindAClinic.defaultProps = {
  clinicList: [],
  clientLocation: null,
  clientLocationAddress: null,
  searchCoords: null,
  country: '',
};

function mapStateToProps(state) {
  return {
    ...state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default withSitecoreContext()(
  connect(mapStateToProps, mapDispatchToProps, null, {
    withRef: true,
  })(FindAClinic),
);
