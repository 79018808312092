/**
 * Handle enter or spacebar key on element as a click action
 */
const HandleKeyboardA11y = (e) => {
  // Spacebar or enter key
  if (e.which === 32 || e.which === 13) {
    e.target.click();
  }
};

export const countriesMiles = ['us'];

export default HandleKeyboardA11y;
