import React from 'react';
import PropTypes from 'prop-types';
import HandleKeyboardA11y from './utils';

export const ClinicFilterChip = (props) => {
  const { index, filter, removeFilterItem } = props;
  const handleClick = () => {
    removeFilterItem(index);
  };
  return (
    <div className="filter-chip">
      {filter.value}
      <span
        role="button"
        className="btn-close"
        tabIndex={0}
        onClick={handleClick}
        onKeyUp={HandleKeyboardA11y}
      />
    </div>
  );
};

const ClinicFilterChipsPanel = (props) => {
  const {
    fetchClinicListPending,
    fetchFilteredResults,
    filterPanelUpdateFilterValues,
    selectedFilters,
    setProductFilters,
  } = props;
  const removeFilterItemCallback = () => {
    filterPanelUpdateFilterValues();
    fetchFilteredResults();
  };
  const removeFilterItem = (index) => {
    const updatedFilterArray = [...selectedFilters];
    updatedFilterArray.splice(index, 1);

    // set filter panel form values
    filterPanelUpdateFilterValues();

    if (!fetchClinicListPending) {
      // set clinic finder filter values
      setProductFilters([...updatedFilterArray], removeFilterItemCallback);
    }
  };

  return (
    <div className="clinicFinder__filter-chip-container">
      {selectedFilters.map((filter, index) => (
        <ClinicFilterChip
          key={`key-${filter.type}-${filter.hashId}`}
          filter={filter}
          index={index}
          removeFilterItem={removeFilterItem}
        />
      ))}
    </div>
  );
};

ClinicFilterChipsPanel.propTypes = {
  fetchClinicListPending: PropTypes.bool,
  fetchFilteredResults: PropTypes.func.isRequired,
  filterPanelUpdateFilterValues: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
  setProductFilters: PropTypes.func.isRequired,
};

ClinicFilterChipsPanel.defaultProps = {
  fetchClinicListPending: true,
  selectedFilters: [],
};

ClinicFilterChip.propTypes = {
  index: PropTypes.number,
  filter: PropTypes.object,
  removeFilterItem: PropTypes.func.isRequired,
};

ClinicFilterChip.defaultProps = {
  index: null,
  filter: {},
};

export default ClinicFilterChipsPanel;
