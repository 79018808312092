import React from 'react';
import { Provider } from 'react-redux';
import FindAClinic from './Components/FindAClinic';
import store from './Components/redux/store';

const ClinicFinder = ({ fields }) => {
  if (typeof window !== 'undefined') {
    const clinicIdMatching =
      [
        sessionStorage.getItem('searchResult'),
        sessionStorage.getItem('clinicId'),
      ] || window.location.pathname.match(/connect\/find-a-clinic\/(.*)\/(.*)/);
    return (
      <Provider store={store}>
        <FindAClinic
          sitecoreFields={fields}
          clinicId={clinicIdMatching ? clinicIdMatching[1] : null}
        />
      </Provider>
    );
  }

  return null;
};

export default ClinicFinder;
