export const Types = {
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_HEADER: 'UPDATE_HEADER',
  UPDATE_HEADER_COUNTRY: 'UPDATE_HEADER_COUNTRY',
  UPDATE_FOOTER_COUNTRY: 'UPDATE_FOOTER_COUNTRY',
  UPDATE_HEARINGAIDSVSIMPLANTABLESOLUTIONS:
    'UPDATE_HEARINGAIDSVSIMPLANTABLESOLUTIONS',
  UPDATE_FINDACLINIC: 'UPDATE_FINDACLINIC',
  GET_CURRENT_POSITION: 'GET_CURRENT_POSITION',
  UPDATE_MARKERS: 'UPDATE_MARKERS',
  UPDATE_CITY: 'UPDATE_CITY',
  UPDATE_HEALTHINSURANCE: 'UPDATE_HEALTHINSURANCE',
  UPDATE_SEARCHRESULTS: 'UPDATE_SEARCHRESULTS',
  GET_GLOBALSEARCH: 'GET_GLOBALSEARCH',
  UPDATE_MAIN: 'UPDATE_MAIN',
  UPDATE_VOLUNTEERS: 'UPDATE_VOLUNTEERS',
  SET_GLOBALSEARCH: 'SET_GLOBALSEARCH',
  SET_GLOBALSEARCHQUERY: 'SET_GLOBALSEARCHQUERY',
  GET_PRODUCTSFORPROFESSIONALS: 'GET_PRODUCTSFORPROFESSIONALS',
  GET_RECIPIENTPRODUCTINFORMATION: 'GET_RECIPIENTPRODUCTINFORMATION',
  GET_PRODUCTPAGE: 'GET_PRODUCTPAGE',
  UPDATE_BAHASERIES: 'UPDATE_BAHASERIES',
  UPDATE_NUCLEUSSERIES: 'UPDATE_NUCLEUSSERIES',
  UPDATE_CONTACTUS: 'UPDATE_CONTACTUS',
  UPDATE_CONTACTFORM: 'UPDATE_CONTACTFORM',
  UPDATE_TASKFOCUSEDCTAOPTION: 'UPDATE_TASKFOCUSEDCTAOPTION',
  SET_CONTACTUSDROPDOWNOPTION: 'SET_CONTACTUSDROPDOWNOPTION',
  GET_CONTACTUSDROPDOWNOPTION: 'GET_CONTACTUSDROPDOWNOPTION',
  GET_VOLUNTEERFORM: 'GET_VOLUNTEERFORM',
  FETCH_GEO_LOCATION_BEGIN: 'FETCH_GEO_LOCATION_BEGIN',
  FETCH_GEO_LOCATION_SUCCESS: 'FETCH_GEO_LOCATION_SUCCESS',
  FETCH_GEO_LOCATION_FAILURE: 'FETCH_GEO_LOCATION_FAILURE',
  FETCH_CLINIC_LIST_BEGIN: 'FETCH_CLINIC_LIST_BEGIN',
  FETCH_CLINIC_LIST_SUCCESS: 'FETCH_CLINIC_LIST_SUCCESS',
  FETCH_CLINIC_LIST_FAILURE: 'FETCH_CLINIC_LIST_FAILURE',
  FETCH_GEO_ADDRESS_BEGIN: 'FETCH_GEO_ADDRESS_BEGIN',
  FETCH_GEO_ADDRESS_SUCCESS: 'FETCH_GEO_ADDRESS_SUCCESS',
  FETCH_GEO_ADDRESS_FAILURE: 'FETCH_GEO_ADDRESS_FAILURE',
  SET_FAQLINKITEM: 'SET_FAQLINKITEM',
  UPDATE_FAQLINKITEM: 'UPDATE_FAQLINKITEM',
};

export default 'Types';
