import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClinicList from './ClinicList';
import ClinicMap from './ClinicMap';
import ClinicFilters from './ClinicFilters';

const mapFn = {
  map: undefined,
};
const searchFn = {
  searchBox: undefined,
};
let _clinicFilters = undefined;
let google = null;
let etimeout;

const settings = {
  defaultZoom: 9,
  detailZoom: 17,
  boundsBuffer: 0.0072,
};

class ClinicNearYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinicSelected: null,
      uiClinicSelected: false,
      mapZoom: settings.defaultZoom,
      mapCenter: { lat: null, lng: null },
      pageState: 'list',
      searchInProgress: false,
      isFiltering: false,
      showSearchAreaButton: false,
      showSearchMyLocation: true,
      showFilters: false,
      selectedFilterArray: [],
      selectedFilters: [],
      deselected: false,
      invalidAddress: false,
      invalidAddressValue: '',
      filterPanelSelectedProductList: [],
      filterPanelSelectedProductType: [],
      filters: {
        productHash: null,
        clinicHash: null,
      },
    };

    this.getMapCenter = this.getMapCenter.bind(this);
    this.getZoom = this.getZoom.bind(this);
    this.handleFilterSelected = this.handleFilterSelected.bind(this);
    this.handleFilterDeSelected = this.handleFilterDeSelected.bind(this);
    this.handleClinicSelected = this.handleClinicSelected.bind(this);
    this.handleClinicDeSelected = this.handleClinicDeSelected.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
    this.handleSearchMyLocation = this.handleSearchMyLocation.bind(this);
    this.handleSearchAreaButtonState =
      this.handleSearchAreaButtonState.bind(this);
    this.handleClickBottom = this.handleClickBottom.bind(this);
    this.handleScrollBottom = this.handleScrollBottom.bind(this);
    this.handleFilterTriggered = this.handleFilterTriggered.bind(this);
    this.onMapMounted = this.onMapMounted.bind(this);
    this.resetFilterValues = this.resetFilterValues.bind(this);
    this.setUiClinicSelected = this.setUiClinicSelected.bind(this);
    this.setTitleBarProducts = this.setTitleBarProducts.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateClinicResults = this.updateClinicResults.bind(this);
    this.setProductFilters = this.setProductFilters.bind(this);
    this.fetchFilteredResults = this.fetchFilteredResults.bind(this);
    this.handleFilterTriggered = this.handleFilterTriggered.bind(this);
    this.handleFilterDeSelected = this.handleFilterDeSelected.bind(this);
    this.handleClickedApplyBtn = this.handleClickedApplyBtn.bind(this);
    this.filterPanelUpdateFilterValues =
      this.filterPanelUpdateFilterValues.bind(this);
    this.filterPanelSetProductType = this.filterPanelSetProductType.bind(this);
    this.filterPanelSetProductList = this.filterPanelSetProductList.bind(this);
  }

  componentDidMount() {
    let newState = {};
    // does a clinicId exist? Came from the mini component or back from the form?
    if (this.props.clinicId) {
      newState = {
        ...newState,
        clinicSelected: this.props.clinicId,
        uiClinicSelected: true,
        pageState: 'detail',
        showSearchAreaButton: false,
        mapZoom: settings.detailZoom,
      };

      const clinic = {};
      if (this.props.clinicCoords) {
        // user came from form
        clinic.latValue = this.props.clinicCoords.lat;
        clinic.longValue = this.props.clinicCoords.lng;

        newState = {
          ...newState,
          mapCenter: {
            lat: this.props.clinicCoords.lat,
            lng: this.props.clinicCoords.lng,
          },
        };
      } else {
        // user came from mini component
        clinic.latValue = this.props.clientLoc.coords.latitude;
        clinic.longValue = this.props.clientLoc.coords.longitude;
      }
      this.panMapToPlace(clinic);
      // filters?
      if (this.props.selectedFilters) {
        const filters = this.props.selectedFilters;
        // values passed to clinic filters
        const products = [];
        filters.map((filter) => {
          if (filter.type === 'clinicType') {
            newState = {
              ...newState,
              filterPanelSelectedProductType: filter,
            };
          } else {
            products.push(filter);
          }
          newState = {
            ...newState,
            filterPanelSelectedProductList: products,
          };
        });
        // passed to clinic filter chips panel
        newState = {
          ...newState,
          selectedFilters: filters,
        };
      }
    } else {
      if (this.state.clinicSelected !== null && !this.props.isSearching) {
        newState.pageState = 'detail';
        newState.mapZoom = settings.detailZoom;
      }
      if (this.state.clinicSelected) {
        newState.pageState = 'detail';
        newState.mapZoom = settings.detailZoom;
      }
      newState.mapCenter = {
        lat: this.props.clientLoc.coords.latitude,
        lng: this.props.clientLoc.coords.longitude,
      };
    }
    this.setState(newState);

    if (!this.props.selectedClinicShown) {
      setTimeout(() => {
        const chosenClinic = null;
        if (chosenClinic !== null && this.state.searchInProgress !== true) {
          this.handleClinicSelected(chosenClinic);
        }
      }, 1000);
    }
  }

  onMapMounted(ref) {
    mapFn.map = ref;
    google = google !== null ? google : window.google; // use Google library loaded on page
  }

  onSearchBoxMounted(ref) {
    searchFn.searchBox = ref;
  }

  handleClickedApplyBtn(event) {
    // Todo: Move function to parent (since all variables are props)
    event.persist();
    event.preventDefault();
    if (!this.props.fetchClinicListPending) {
      this.setProductFilters(
        [
          ...this.state.filterPanelSelectedProductType,
          ...this.state.filterPanelSelectedProductList,
        ],
        this.fetchFilteredResults,
      );
      this.handleFilterTriggered();
      this.handleFilterDeSelected();
    }
  }

  // sets all selected filter options (type and product)
  setProductFilters(filterList, callback) {
    const productType = []; // object type
    const productList = []; // array type
    // let productTypeObject = null;

    const cleanFilterList = filterList.filter(
      (item) => item !== null && item !== undefined,
    ); // remove null and undefined items from array
    // so rt out filters between type and products
    cleanFilterList.forEach((filter) => {
      switch (filter.type) {
        case 'clinicType':
          productType.push(filter);
          // productTypeObject = filter;
          break;
        case 'clinicProduct':
          productList.push(filter);
          break;
        default:
          break;
      }
    });

    const newState = {
      selectedProductType: productType,
      selectedFilterProducts: productList,
      selectedFilters: [...productType, ...productList],
    };

    // set new state
    if (typeof callback === 'function') {
      this.setState(newState, () => {
        callback();
      });
    } else {
      this.setState(newState);
    }
  }

  filterPanelSetProductType(productType) {
    // const updatedProductType = productType === null ? {} : productType;
    this.setState({
      filterPanelSelectedProductType: productType,
    });
  }

  filterPanelSetProductList(productList) {
    this.setState({
      filterPanelSelectedProductList: productList,
    });
  }

  /**
   * Update the filter panel form values when the filter chips are removed
   */
  filterPanelUpdateFilterValues() {
    this.filterPanelSetProductType(this.state.selectedProductType);
    this.filterPanelSetProductList(this.state.selectedFilterProducts);
  }

  fetchFilteredResults() {
    // Add all product ids to get product hash
    const selectedProductHash = this.state.selectedFilterProducts.reduce(
      (a, b) => {
        const currentValue = b.type === 'clinicProduct' ? b.hashId : 0;
        return Number(a) + currentValue;
      },
      0,
    );
    const selectedProductTypeHash = this.state.selectedProductType.reduce(
      (a, b) => {
        const currentValue = b.type === 'clinicType' ? Number(b.hashId) : 0;
        return Number(a) + currentValue;
      },
      0,
    );

    const coords = new google.maps.LatLng({
      lat: this.props.searchCoords.lat,
      lng: this.props.searchCoords.lng,
    });

    const params = {
      coords,
      productHash: selectedProductHash,
      clinicHash: selectedProductTypeHash,
    };
    this.updateFilters(params);
  }

  getZoom() {
    return this.refs.getZoom();
  }

  setTitleBarProducts(productList) {
    this.setState({
      selectedFilterArray: productList.filter((val) => val !== null), // filter out null elements from array
    });
  }

  getMapCenter() {
    return mapFn.map.getCenter();
  }

  setUiClinicSelected(boolState) {
    this.setState({ uiClinicSelected: boolState });
  }

  handleClinicSelected(clinic) {
    this.setState({
      clinicSelected: clinic.id,
      uiClinicSelected: true,
      pageState: 'detail',
      showSearchAreaButton: false,
      mapZoom: settings.detailZoom,
    });
    this.panMapToPlace(clinic);
  }

  handleClinicDeSelected() {
    this.setState({
      clinicSelected: null,
      pageState: 'list',
      mapZoom: settings.defaultZoom,
      deselected: true,
    });
  }

  handleFilterSelected() {
    this.setState({
      showFilters: true,
    });
  }

  handleFilterDeSelected() {
    this.setState({
      showFilters: false,
    });
  }

  handleFilterTriggered() {
    this.setState({
      isFiltering: true,
    });
  }

  // Reset values after searching a different location
  resetFilterValues() {
    this.setState({ selectedFilterArray: [] });
    _clinicFilters.resetFilterValues();
  }

  handleSearchAreaButtonState(boolState) {
    this.setState({ showSearchAreaButton: boolState });
  }

  updateFilters(params) {
    const filters = {
      productHash: params.productHash,
      clinicHash: params.clinicHash,
    };
    this.setState({ filters }, () => {
      this.updateClinicResults(params.coords);
    });
    // this.setState({test:'this is a test'})
  }

  handlePlaceChange(place, searchString) {
    if (place === undefined) {
      this.setState({
        invalidAddress: true,
        invalidAddressValue: searchString,
      });
      return false;
    }
    // call dispatch to update clinic list
    const resultLocation = place.geometry.location;
    // prepare filter object
    this.updateClinicResults(resultLocation);
    this.props.handlePlaceChange(searchString);
    this.setState({ isFiltering: false, invalidAddress: false });
    // this.resetFilterValues()
  }

  handleSearchMyLocation() {
    // fetch geo location before fetching clinic
    this.props.fetchGeoLocation(
      (position) => {
        const geoCoords = new window.google.maps.LatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.updateClinicResults(geoCoords);
      },
      (err) => {
        console.log('error:', err);
      },
    );
  }

  updateClinicResults(coords) {
    const endpoint = this.props.sitecoreFields.configuration.fields.url.value;
    this.handleClinicDeSelected();
    this.setState({
      mapCenter: coords,
      searchInProgress: true,
      mapZoom: settings.defaultZoom,
    });
    this.props.updateClinicList({
      coords: {
        latitude: coords.lat(),
        longitude: coords.lng(),
      },
      config: {
        ...this.state.filters,
        endpoint: endpoint,
      },
    });
  }

  panMapToPlace(clinic) {
    // when clicking on a clinic from the list
    const boundsEast = Number(clinic.longValue) - settings.boundsBuffer;
    const boundsNorth = Number(clinic.latValue) - settings.boundsBuffer;
    const boundsSouth = Number(clinic.latValue) + settings.boundsBuffer;
    const boundsWest = Number(clinic.longValue) + settings.boundsBuffer;
    function callMap() {
      if (google) {
        const sw = new google.maps.LatLng(boundsSouth, boundsWest);
        const ne = new google.maps.LatLng(boundsNorth, boundsEast);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(sw);
        bounds.extend(ne);
        if (mapFn.map) {
          mapFn.map.panTo({
            lat: Number(clinic.latValue),
            lng: Number(clinic.longValue),
          });
        }
      }
      clearTimeout(etimeout);
    }
    etimeout = setTimeout(callMap, 1000);
  }

  handleClickBottom(event) {
    let el = event.target;
    while ((el = el.parentElement) && !el.tagName.includes('UL')) {}
    let increase = 2;
    const scrollEase = window.setInterval(() => {
      if (el.scrollTop < el.scrollHeight - el.offsetHeight) {
        el.scrollTop += increase;
        increase *= increase;
      } else {
        clearInterval(scrollEase);
      }
    }, 40);
  }

  handleScrollBottom(event) {
    const el = event.target;
    if (el.scrollTop === el.scrollHeight - el.offsetHeight) {
      el.classList.add('at-bottom');
    } else {
      el.classList.remove('at-bottom');
    }
  }

  render() {
    const {
      clinicList,
      fetchClinicListPending,
      handleFetchedGeoAddress,
      clientLoc,
      searchCoords,
      valueSearched,
      fetchedGeoAddress,
      fetchGeoAddress,
      sitecoreFields,
      sitecoreContext,
    } = this.props;

    const {
      clinicSelected,
      isFiltering,
      invalidAddress,
      invalidAddressValue,
      mapCenter,
      pageState,
      selectedFilterArray,
      selectedFilters,
      showFilters,
      showSearchAreaButton,
      showSearchMyLocation,
      uiClinicSelected,
      mapZoom,
    } = this.state;

    const gMapAPIKey = sitecoreContext.metadata.mapsKey; //'AIzaSyB-Le6GLJA6j3d0uwWedAdkFa9LYt6RlRs';
    const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${gMapAPIKey}`;

    return (
      <section id="find-clinic" className="clinicFinder">
        <div className="clinicFinder__searchContainer">
          <div className="l-padding">
            <div className="title-banner">
              <div className="container">
                <h1>{sitecoreFields.title.value}</h1>
                <p>{sitecoreFields.description.value}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="l-padding">
          <div
            className={`clinicFinder__clinicContainer ${
              showFilters ? 'showFilters__filters-active' : ''
            }`}
          >
            <div className="clinicFinder__clinicMap">
              <ClinicMap
                center={mapCenter}
                clientLoc={clientLoc}
                clinicList={clinicList}
                fetchedGeoAddress={fetchedGeoAddress}
                fetchGeoAddress={fetchGeoAddress}
                getMapCenter={this.getMapCenter}
                googleMapURL={googleMapURL}
                handleClinicDeSelected={this.handleClinicDeSelected}
                handleClinicSelected={this.handleClinicSelected}
                handleFetchedGeoAddress={handleFetchedGeoAddress}
                handlePlacesChanged={this.handlePlaceChange}
                handleSearchAreaButtonState={this.handleSearchAreaButtonState}
                handleSearchMyLocation={this.handleSearchMyLocation}
                mapFn={mapFn}
                onMapMounted={this.onMapMounted}
                searchCoords={searchCoords}
                selectedClinic={clinicSelected}
                setUiClinicSelected={this.setUiClinicSelected}
                showSearchAreaButton={showSearchAreaButton}
                showSearchMyLocation={showSearchMyLocation}
                updateClinicResults={this.updateClinicResults}
                uiClinicSelected={uiClinicSelected}
                zoom={mapZoom}
                sitecoreFields={sitecoreFields}
                valueSearched={valueSearched}
              />
            </div>
            <div className="clinicFinder__clinicResults">
              <ClinicList
                clinicList={clinicList}
                fetchClinicListPending={fetchClinicListPending}
                fetchFilteredResults={this.fetchFilteredResults}
                handleClinicSelected={this.handleClinicSelected}
                handleClinicDeSelected={this.handleClinicDeSelected}
                handleFilterSelected={this.handleFilterSelected}
                handleFilterDeSelected={this.handleFilterDeSelected}
                handleScrollBottom={this.handleScrollBottom}
                invalidAddress={invalidAddress}
                invalidAddressValue={invalidAddressValue}
                isFiltering={isFiltering}
                pageState={pageState}
                searchCoords={searchCoords}
                selectedClinic={clinicSelected}
                selectedFilterArray={selectedFilterArray}
                selectedFilters={selectedFilters}
                setProductFilters={this.setProductFilters}
                showFilters={showFilters}
                sitecoreFields={sitecoreFields}
                valueSearched={valueSearched}
                fetchGeoAddress={fetchGeoAddress}
                fetchedGeoAddress={fetchedGeoAddress}
                handleFetchedGeoAddress={handleFetchedGeoAddress}
                filterPanelUpdateFilterValues={
                  this.filterPanelUpdateFilterValues
                }
              />
              <div
                className={`clinicFinder__filterPanel ${
                  showFilters ? 'active' : ''
                }`}
              >
                <ClinicFilters
                  fetchClinicListPending={fetchClinicListPending}
                  updateFilters={this.updateFilters}
                  handleClickedApplyFilter={this.handleClickedApplyBtn}
                  ref={(child) => {
                    _clinicFilters = child;
                  }}
                  filterPanelSetProductType={this.filterPanelSetProductType}
                  filterPanelSetProductList={this.filterPanelSetProductList}
                  filterPanelSelectedProductList={
                    this.state.filterPanelSelectedProductList
                  }
                  filterPanelSelectedProductType={
                    this.state.filterPanelSelectedProductType
                  }
                  searchCoords={searchCoords}
                  sitecoreFields={this.props.sitecoreFields}
                  google={google}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ClinicNearYou.propTypes = {
  clinicList: PropTypes.arrayOf(PropTypes.object),
  clientLoc: PropTypes.objectOf(PropTypes.object),
  fetchClinicListPending: PropTypes.bool.isRequired,
  fetchedGeoAddress: PropTypes.bool.isRequired,
  fetchGeoAddress: PropTypes.func.isRequired,
  fetchGeoLocation: PropTypes.func.isRequired,
  handleFetchedGeoAddress: PropTypes.func.isRequired,
  handlePlaceChange: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  searchCoords: PropTypes.objectOf(PropTypes.number),
  selectedClinicShown: PropTypes.bool.isRequired,
  updateClinicList: PropTypes.func.isRequired,
  valueSearched: PropTypes.string,
};

ClinicNearYou.defaultProps = {
  clinicList: [],
  clientLoc: null,
  searchCoords: null,
  valueSearched: null,
};

export default ClinicNearYou;
