import axios from 'axios';
import { Types } from './user-types';

export function updateMarkers(data) {
  return function (dispatch, getState) {
    dispatch({ type: Types.UPDATE_MARKERS, payload: data });
  };
}

export function updateCity(data) {
  return function (dispatch, getState) {
    dispatch({ type: Types.UPDATE_CITY, payload: data.city });
  };
}

export function setGlobalSearch(data) {
  return function (dispatch, getState) {
    dispatch({ type: Types.SET_GLOBALSEARCH, payload: data });
  };
}

export function setGlobalSearchQuery(data) {
  return function (dispatch, getState) {
    dispatch({ type: Types.SET_GLOBALSEARCHQUERY, payload: data });
  };
}

/**
 * Geo location action using the Browser Geolocation API.
 export function fetchGeoLocation(resolve, reject) {
 * @param  {function} resolve [Callback function on success]
 * @param  {function} reject  [Callback function on error]
 * @return {object}         [Browser Geo API coordinates]
 */

function getUrlVars() {
  const vars = {};
  const parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => {
      vars[key] = value;
    },
  );
  return vars;
}

export function fetchGeoLocation(resolve, reject) {
  // export function fetchGeoLocationOrig(resolve, reject){
  return async function (dispatch, getState) {
    const lat = getUrlVars().lat;
    const lng = getUrlVars().lng;
    if (lat) {
      dispatch({ type: Types.FETCH_GEO_LOCATION_BEGIN });
      const position = {
        coords: {
          latitude: parseFloat(lat),
          longitude: parseFloat(lng),
        },
      };
      dispatch({
        type: Types.FETCH_GEO_LOCATION_SUCCESS,
        payload: position,
      });

      resolve(position);
    } else {
      dispatch({ type: Types.FETCH_GEO_LOCATION_BEGIN });
      const data = window.navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Navigator Geolocation - - - - - - - - - - - - - - ');
          dispatch({
            type: Types.FETCH_GEO_LOCATION_SUCCESS,
            payload: position,
          });
          resolve(position);
        },
        (err) => {
          // const err='error getting location'
          console.log(err);
          const onSuccess = function (geoipResponse) {
            console.log('Maxmind JS - - - - - - - - - - - - - - ');
            const position = {
              coords: {
                latitude: geoipResponse.location.latitude,
                longitude: geoipResponse.location.longitude,
              },
              province: geoipResponse.subdivisions[0].iso_code,
              country: geoipResponse.country.iso_code,
            };
            dispatch({
              type: Types.FETCH_GEO_LOCATION_SUCCESS,
              payload: position,
            });
            resolve(position);
          };
          /* If we get an error we will */
          const onError = function (error) {
            console.log(
              error,
              'We were unable to find location via HTML5 geolocation or through your IP address. Defaulting to country.',
            );
            dispatch({
              type: Types.FETCH_GEO_LOCATION_FAILURE,
              payload: { error: error },
            });
            reject(error);
          };

          // add maxmind ip script
          const aScript = document.createElement('script');
          aScript.type = 'text/javascript';
          aScript.src = '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js';
          aScript.addEventListener('load', () => {
            window.geoip2.city(onSuccess, onError);
          });
          document.head.appendChild(aScript);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        },
      );
      return data;
    }
  };
}

export function fetchGeoAddress(resolve, reject, args = {}) {
  const geocoder = new window.google.maps.Geocoder();
  // var lat = 40.714224;
  // var long = -73.961452;
  const lat = args.coords.latitude;
  const lng = args.coords.longitude;
  const latlng = { lat, lng };
  const getShortName = (item) => {
    return item ? item.short_name || null : null;
  };
  return async function (dispatch, getState) {
    dispatch({ type: Types.FETCH_GEO_ADDRESS_BEGIN });
    const data = geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        dispatch({
          type: Types.FETCH_GEO_ADDRESS_SUCCESS,
          payload: {
            ...results,
            province: getShortName(
              results[0].address_components.filter(
                (address) =>
                  address.types.indexOf('administrative_area_level_1') >= 0,
              )[0],
            ),
            // province: results[0].address_components.filter(address => address.types.indexOf('administrative_area_level_1') >= 0)[0].short_name,
            country: results[0].address_components.filter(
              (address) => address.types.indexOf('country') >= 0,
            )[0].short_name,
          },
        });
        resolve(results);
      } else if (status === 'OVER_QUERY_LIMIT') {
        console.log('OVER_QUERY_LIMIT');
        // too many queries have been sent.
        return false;
      } else {
        const err = `Geocoder failed due to: ${status}`;
        console.log(err);
        dispatch({
          type: Types.FETCH_GEO_ADDRESS_FAILURE,
          payload: { error: err },
        });
        reject(err);
      }
    });
    return data;
  };
}

/**
 * End point for SFDC API
 * Notes: Clinic type is now determined by clinicHash
 * Todo: Set configurable endpoint
 * @param  {Object} args [description]
 * @return {[type]}      [description]
 */
export function fetchClinicList(args = {}) {
  const defaults = {
    productHash: 15,
    clinicHash: 3,
    count: 30,
    radius: 500,
    rk: 2,
  };

  const endpoint = args.config.endpoint;
  const params = args.clinicId
    ? {
        ...defaults,
        clinicId: args.clinicId,
        productHash: 63, // select all clinic products
      }
    : {
        ...defaults,
        latValue: args.coords.latitude,
        longValue: args.coords.longitude,
        productHash: args.config.productHash
          ? args.config.productHash
          : defaults.productHash,
        clinicHash: args.config.clinicHash
          ? args.config.clinicHash
          : defaults.clinicHash,
      };

  // Note all endpoints defined in Sitecore CMS config
  const endpointURL = `${endpoint}?ReqData=${encodeURIComponent(
    JSON.stringify(params),
  )}`;

  const dataActionFunc = async (dispatch) => {
    dispatch({
      type: Types.FETCH_CLINIC_LIST_BEGIN,
      payload: params,
    });
    const data = axios
      .get(endpointURL)
      .then((res) => {
        dispatch({
          type: Types.FETCH_CLINIC_LIST_SUCCESS,
          payload: res,
        });
        if (args.clinicId && res.data) {
          // failure
          if (res.data.success === -1) {
            dispatch({
              type: Types.FETCH_CLINIC_BY_ID_ERROR,
              payload: {
                message: `No clinics matched given id: ${args.clinicId}`,
                error: true,
              },
            });
          }
          // success
          if (res.data.clinics) {
            const clinic = res.data.clinics.filter(
              (c) => c.id === args.clinicId,
            )[0];
            console.log(clinic);
            if (clinic) {
              const position = {
                coords: {
                  latitude: parseFloat(clinic.latValue),
                  longitude: parseFloat(clinic.longValue),
                },
              };
              dispatch({
                type: Types.FETCH_GEO_LOCATION_SUCCESS,
                payload: position,
              });
            } else {
              dispatch({
                type: Types.FETCH_CLINIC_BY_ID_ERROR,
                payload: {
                  message: `No clinics matched given id: ${args.clinicId}`,
                  error: true,
                },
              });
            }
          }
        }
        return null;
      })
      .catch((err) => {
        dispatch({
          type: Types.FETCH_CLINIC_LIST_FAILURE,
          payload: { error: err },
        });
      });

    return data;
  };

  return dataActionFunc;
}
