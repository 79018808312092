import React from 'react';
import PropTypes from 'prop-types';

const ClinicListTitleBar = (props) => {
  const {
    count,
    handleClinicDeSelected,
    handleFilterSelected,
    handleFilterDeSelected,
    isFiltering,
    pageState,
    selectedFilterArray,
    showFilters,
    sitecoreFields,
  } = props;

  if (pageState === 'list') {
    return (
      <div className="clinic-list-title-bar">
        <span>
          {sitecoreFields.result_foundResult_1.value} {count}{' '}
          {sitecoreFields.result_foundResult_2.value}{' '}
          {count === 1
            ? sitecoreFields.result_foundResult_clinic.value
            : sitecoreFields.result_foundResult_clinics.value}
        </span>
        {(isFiltering || count > 0) &&
          (showFilters ? (
            <button
              type="button"
              className="filter-label"
              onClick={handleFilterDeSelected}
            >
              {sitecoreFields.filter_closeFilter.value}
            </button>
          ) : (
            <button
              type="button"
              className="filter-label"
              onClick={handleFilterSelected}
            >
              {selectedFilterArray && selectedFilterArray.length > 0
                ? sitecoreFields.filter_editFilters.value
                : sitecoreFields.filter_filter.value}
            </button>
          ))}
        {selectedFilterArray && selectedFilterArray.length > 0 && (
          <div className="product-list">
            <span>Filtered by </span>
            {selectedFilterArray.map((filter, index) => (
              <span key={filter}>
                {index !== 0 && ', '}
                {filter.toString().toLowerCase()}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      className="clinic-list-title-bar return"
      role="button"
      tabIndex={0}
      onClick={handleClinicDeSelected}
      onKeyPress={handleClinicDeSelected}
    >
      {sitecoreFields.result_searchResults.value}
    </div>
  );
};

ClinicListTitleBar.propTypes = {
  count: PropTypes.number.isRequired,
  handleClinicDeSelected: PropTypes.func,
  handleFilterSelected: PropTypes.func.isRequired,
  handleFilterDeSelected: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
  pageState: PropTypes.string.isRequired,
  selectedFilterArray: PropTypes.arrayOf(PropTypes.string),
  showFilters: PropTypes.bool.isRequired,
  sitecoreFields: PropTypes.object.isRequired,
};

ClinicListTitleBar.defaultProps = {
  selectedFilterArray: [],
  handleClinicDeSelected: () => {},
};

export default ClinicListTitleBar;
