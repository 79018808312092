import { Types } from '../user-types';

const initialState = {
  name: null,
  email: null,
  title: null,
  headerTitle: null,
  headerCountry: null,
  footerCountry: null,
  footerTitle: null,
  dataHeader: null,
  dataHome: null,
  dataHearingAidVsImplantableSolutions: null,
  dataFindAClinic: null,
  dataHealthInsurance: null,
  dataSearchResults: null,
  getCurrentPosition: null,
  getGlobalSearch: null,
  globalSearch: false,
  globalSearchQuery: null,
  dataProductPage: null,
  dataContactUs: null,
  dataContactForm: null,
  dataUpdateVolunteers: null,
  dataContactUsDropdownOption: null,
  dataUpdateMain: null,
  dataVolunteerForm: null,
  getVolunteerForm: null,
  markers: [],
  lat: 0,
  lng: 0,
  fetchingGeoLocationFirstTime: true,
  fetchGeoLocationError: null,
  fetchGeoLocationPending: true,
  fetchClinicListPending: true,
  fetchGeoAddressPending: true,
  fetchGeoAddressError: null,
  clientLocation: null,
  clientLocationAddress: null,
  clinicList: [],
  searchCoords: null,
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case Types.UPDATE_NAME:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
      };
    case Types.UPDATE_HEADER:
      return { ...state, dataHeader: action.payload };
    case Types.UPDATE_HEADER_COUNTRY:
      return { ...state, headerCountry: action.payload.country };
    case Types.UPDATE_FOOTER_COUNTRY:
      return { ...state, footerCountry: action.payload.country };
    case Types.UPDATE_HEARINGAIDSVSIMPLANTABLESOLUTIONS:
      return {
        ...state,
        dataHearingAidVsImplantableSolutions: action.payload,
      };
    case Types.UPDATE_FINDACLINIC:
      return { ...state, dataFindAClinic: action.payload };
    case Types.GET_CURRENT_POSITION:
      return {
        ...state,
        lat: action.payload.lat,
        lng: action.payload.lng,
      };
    case Types.UPDATE_MARKERS:
      return {
        ...state,
        markers: action.payload.markers,
        markersAreLoaded: action.payload.markersAreLoaded,
      };
    case Types.UPDATE_CITY:
      return { ...state, city: action.payload };
    case Types.UPDATE_HEALTHINSURANCE:
      return { ...state, dataHealthInsurance: action.payload };
    case Types.UPDATE_SEARCHRESULTS:
      return { ...state, dataSearchResults: action.payload };
    case Types.GET_GLOBALSEARCH:
      return { ...state, getGlobalSearch: action.payload };
    case Types.UPDATE_MAIN:
      return { ...state, dataUpdateMain: action.payload };
    case Types.UPDATE_VOLUNTEERS:
      return { ...state, dataUpdateVolunteers: action.payload };
    case Types.UPDATE_CONTACTUS:
      return { ...state, dataContactUs: action.payload };
    case Types.UPDATE_CONTACTFORM:
      return { ...state, dataContactForm: action.payload };
    case Types.SET_GLOBALSEARCH:
      return { ...state, globalSearch: action.payload };
    case Types.SET_GLOBALSEARCHQUERY:
      return { ...state, globalSearchQuery: action.payload };
    case Types.GET_PRODUCTPAGE:
      return { ...state, dataProductPage: action.payload };
    case Types.UPDATE_TASKFOCUSEDCTAOPTION:
      return { ...state, dataTaskFocusedCTAOption: action.payload };
    case Types.SET_CONTACTUSDROPDOWNOPTION:
      return {
        ...state,
        dataContactUsDropdownOption: action.payload,
      };
    case Types.GET_CONTACTUSDROPDOWNOPTION:
      return {
        ...state,
        dataContactUsDropdownOption: action.payload,
      };
    case Types.GET_VOLUNTEERFORM:
      return { ...state, dataVolunteerForm: action.payload };
    case Types.FETCH_GEO_LOCATION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchGeoLocationPending: true,
        fetchGeoLocationError: null,
      };
    case Types.FETCH_GEO_LOCATION_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchGeoLocationPending: false,
        fetchGeoLocationError: null,
        fetchingGeoLocationFirstTime: false,
        clientLocation: action.payload,
      };

    case Types.FETCH_GEO_ADDRESS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchGeoAddressPending: false,
        fetchGeoAddressError: action.payload.error,
      };
    case Types.FETCH_GEO_ADDRESS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchGeoAddressPending: true,
        fetchGeoAddressError: null,
      };

    case Types.FETCH_GEO_ADDRESS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchGeoAddressPending: false,
        fetchGeoAddressError: null,
        clientLocationAddress: action.payload,
      };

    case Types.FETCH_GEO_LOCATION_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchGeoLocationPending: false,
        fetchGeoLocationError: action.payload.error,
      };
    case Types.FETCH_CLINIC_LIST_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchClinicListPending: true,
        fetchClinicListError: null,
        searchCoords: {
          lat: action.payload.latValue,
          lng: action.payload.longValue,
        },
      };

    case Types.FETCH_CLINIC_LIST_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchClinicListPending: false,
        fetchClinicListError: null,
        // clinicList: formatClinicList(action.payload.data.clinics)
        clinicList: action.payload.data.clinics,
      };

    case Types.FETCH_CLINIC_LIST_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchClinicListPending: false,
        fetchClinicListError: action.payload.error,
      };

    case Types.SET_FAQLINKITEM:
      return {
        ...state,
        faqLinkClicked: true,
        faqLinkItem: action.payload,
      };
    case Types.UPDATE_FAQLINKITEM:
      return {
        ...state,
        faqLinkClicked: false,
      };

    default:
      return state;
  }
}
